import React, { FC, RefObject, useEffect, useRef, useState } from "react";

import { ReactComponent as AddIcon } from "assets/images/add-regular.svg";
import { currentSchoolIdVar } from "store/school";
import { useReactiveVar } from "@apollo/client";
import {
  OrderDirection,
  useAssignStudentsToFolderMutation,
  useGetAllFoldersQuery,
} from "generated/graphql";
import { useTranslation } from "react-i18next";

import { ReactComponent as CheckCircle } from "assets/images/check-circle-blue.svg";
import AddFolderModal from "pages/Teacher/Folders/Modals/AddFolderModal";
import {
  Header,
  Wrapper,
  StyledLink,
  Content,
  FolderItem,
  FolderList,
} from "./styles";

import { Props } from "./types";
import { SearchInput } from "../FolderDropdown/styles";

const FolderSelector: FC<Props> = ({ userId, onClose, currentFolderName }) => {
  const currentSchoolId = useReactiveVar(currentSchoolIdVar);

  const { t } = useTranslation();

  const ref = useRef<HTMLDivElement>(null);

  const { data: allFolders, refetch: refetchFolders } = useGetAllFoldersQuery({
    variables: {
      where: { school: { id: { equals: currentSchoolId } } },
      orderBy: [{ name: OrderDirection.Asc }],
    },
  });

  const [assignStudentMutation] = useAssignStudentsToFolderMutation();

  const assignStudent = async (folderId: string, folderName: string) => {
    await assignStudentMutation({
      variables: {
        folderId: Number(folderId),
        schoolId: Number(currentSchoolId),
        studentIds: [Number(userId)],
      },
    }).then(() => onClose(Number(userId), folderName));
  };

  const [addFolderModalOpen, setAddFolderModalOpen] = useState(false);

  const [filterFoldersValue, setFilterFoldersValue] = useState("");

  const handleChange = (e: any) => {
    setFilterFoldersValue(e.target.value.toLowerCase());
  };

  useEffect(() => {
    function handleClickOutside(
      event: MouseEvent,
      localRef: RefObject<HTMLElement>
    ) {
      if (
        ref &&
        ref.current &&
        localRef === ref &&
        localRef.current &&
        !localRef.current.contains(event.target as HTMLElement)
      ) {
        onClose(null);
      }
    }

    document.addEventListener("mouseup", (e) => handleClickOutside(e, ref));
    return () => {
      document.removeEventListener("mouseup", (e) =>
        handleClickOutside(e, ref)
      );
    };
  }, [ref, onClose]);

  useEffect(() => {
    refetchFolders();
  }, [refetchFolders]);

  return (
    <Wrapper ref={ref}>
      <AddFolderModal
        isOpen={addFolderModalOpen}
        title="Create new folder"
        userId={userId}
        onClose={() => {
          setAddFolderModalOpen(false);
          refetchFolders();
          onClose(null);
        }}
      />
      <Header>
        <div>Select the folder</div>
        <StyledLink onClick={() => setAddFolderModalOpen(true)}>
          <AddIcon />
          New
        </StyledLink>
      </Header>
      <Content>
        <SearchInput
          placeholder={t("global.folderSearch")}
          onChange={handleChange}
        />
        <FolderList>
          {allFolders &&
            allFolders.folders
              ?.filter((folder) =>
                folder.name?.toLowerCase().includes(filterFoldersValue)
              )
              .map((folder) => (
                <FolderItem
                  selected={folder.name === currentFolderName}
                  key={folder.id}
                  onClick={() => assignStudent(folder.id, folder.name || "")}
                >
                  {folder.name}
                  {folder.name === currentFolderName && <CheckCircle />}
                </FolderItem>
              ))}
        </FolderList>
      </Content>
    </Wrapper>
  );
};

export default FolderSelector;
