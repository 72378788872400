/* eslint-disable no-underscore-dangle */
import { useReactiveVar } from "@apollo/client";
import { FormikProps, useFormik } from "formik";
import {
  useGetAllArchivedStudentsMetaQuery,
  useGetAllEmailTemplatesQuery,
  useGetArchivedStudentsQuery,
  useGetArchivedStudentsYearsQuery,
  useRevokeInvitationMutation,
  useSendEmailMutation,
  useUnarchiveStudentsMutation,
} from "generated/graphql";
import { required } from "helpers/formValidation";
import useClickOutside from "hooks/clickOutSide";
import useDebounce from "hooks/Debounce";
import { useToast } from "hooks/Toast";
import StudentActionDrawer from "pages/Teacher/Students/Components/StudentActionDrawer";
import StudentsListActions from "pages/Teacher/Students/Components/StudentsList";
import StudentMessageModal from "pages/Teacher/Students/Modals/StudentMessageModal";
import { TemplatesType } from "pages/Teacher/Students/types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { currentSchoolIdVar } from "store/school";
import { SelectableStudent } from "types/student";
import * as Yup from "yup";
import paths from "routes/paths";
import { useHistory } from "react-router-dom";
import StudentList from "../StudentList";
import { ActionDrawerWrapper, Container, StudentsListWrapper } from "./styles";

const PreviousYearsData = () => {
  const { t } = useTranslation();
  const Toast = useToast();

  const currentSchoolId = useReactiveVar(currentSchoolIdVar);

  const { data: emailTemplates } = useGetAllEmailTemplatesQuery();
  const [sendEmail] = useSendEmailMutation();

  const [currentPage, setCurrentPage] = useState(1);
  const [studentsPerPage, setStudentsPerPage] = useState(10);
  const [studentSearchTerm, setStudentSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(studentSearchTerm);
  const [areStudentsAttached, setAreStudentsAttached] = useState(false);
  const [selectedStudents, setSelectedStudents] = useState<SelectableStudent[]>(
    []
  );
  const [isStudentActionModalOpen, setIsStudentActionModalOpen] = useState(
    false
  );
  const [selectedTemplateId, setSelectedTemplateId] = useState<TemplatesType>(
    "custom"
  );
  const [searchStudent, setSearchStudent] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [pageOptionsDropdownVisible, setPageOptionsDropdownVisible] = useState(
    false
  );
  const [selectedYear, setSelectedYear] = useState<number | undefined>(
    undefined
  );

  const { data: allArchiveYears } = useGetArchivedStudentsYearsQuery({
    variables: { schoolId: Number(currentSchoolId) },
  });

  const [revokeInvitation] = useRevokeInvitationMutation();
  const [unarchiveStudents] = useUnarchiveStudentsMutation();

  const handleToggleStudent = (student: SelectableStudent): void => {
    const isAlreadyAdded = selectedStudents.find(
      (selStudent) => selStudent.user.id === student.user.id
    );

    if (!isAlreadyAdded) {
      setSelectedStudents((prevState) => [...prevState, student]);
    } else {
      setSelectedStudents((prevState) =>
        prevState.filter((selStudent) => selStudent.user.id !== student.user.id)
      );
    }
  };

  const {
    data: visibleStudentsData,
    loading: studentsLoading,
    refetch: studentsRefetch,
  } = useGetArchivedStudentsQuery({
    variables: {
      searchTerm: debouncedSearchTerm,
      schoolId: Number(currentSchoolId),
      skip: (currentPage - 1) * studentsPerPage,
      first: studentsPerPage,
      schoolYear: selectedYear,
    },
  });

  const findStudentByName = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setStudentSearchTerm(e.currentTarget.value);
  };

  const handleMessageStudent = (student: SelectableStudent) => {
    setSelectedStudents([student]);
    setIsStudentActionModalOpen(true);
  };

  const onTemplateButtonPress = (id: TemplatesType) => {
    setSelectedTemplateId(id);
  };

  const emailForm: FormikProps<{ title: string; content: string }> = useFormik({
    initialValues: { title: "", content: "" },
    enableReinitialize: true,
    validationSchema: Yup.object({
      title: required,
      content: required,
    }),

    onSubmit: ({ title, content }, { resetForm }) => {
      const userIds = selectedStudents.map((student) =>
        Number(student.user.id)
      );

      if (userIds.length > 0) {
        sendEmail({
          variables: {
            emailTitle: title,
            emailContent: `<p>${content.replaceAll("\n", "<br />")}</p>`,
            userIds,
            schoolId: Number(currentSchoolId),
            addReport: areStudentsAttached,
          },
        })
          .then(() => {
            Toast("success", t("successAction.emailSent"));
          })
          .catch((e) => {
            Toast("error", e.message || t("errorAction.generalError"));
          })
          .finally(() => {
            resetForm();
            setIsStudentActionModalOpen(false);
          });
      }
    },
  });

  const selectTemplateText = () => {
    if (emailTemplates && emailTemplates.emailTemplates) {
      const selectedTemplateText = emailTemplates.emailTemplates.find(
        (el) => el && el.type === selectedTemplateId
      );
      emailForm.setValues({
        title: selectedTemplateText?.title || "",
        content: selectedTemplateText?.content || "",
      });
    }
  };

  const toggleAttachStudents = () => {
    setAreStudentsAttached((prevState) => !prevState);
  };

  const handleStudentSearch = (text: string) => {
    setSearchStudent(text);
  };

  const closePageOptionsDropdown = () => {
    setPageOptionsDropdownVisible(false);
  };

  const closeAllModals = () => {
    setIsStudentActionModalOpen(false);
    setSelectedTemplateId("custom");
    selectTemplateText();
    studentsRefetch();
    setAreStudentsAttached(false);
    setSelectedStudents([]);
  };

  const closeDropdowns = () => {
    closePageOptionsDropdown();
  };

  const handlePagePress = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const history = useHistory();

  const openPageOptionsDropdown = () => setPageOptionsDropdownVisible(true);

  const handleStudentPageChange = (pageItemsNum: number) => {
    setStudentsPerPage(pageItemsNum);
    setCurrentPage(1);
  };

  const {
    visible: addStudentDropdownVisible,
    setVisible: setAddStudentDropdownVisible,
    ref: containerRef,
  } = useClickOutside();

  const redirectToAddStudent = () => history.push(paths.studentsAdd);

  const {
    data: studentsMeta,
    refetch: studentsMetaRefetch,
  } = useGetAllArchivedStudentsMetaQuery({
    variables: {
      schoolId: Number(currentSchoolId),
      // TODO: schoolYear filter
    },
  });

  const allStudentsNumber = studentsMeta?.allArchivedStudentsMeta?.count || 0;

  const handleRemoveStudent = (id: string) => {
    revokeInvitation({
      variables: { userIds: [Number(id)], schoolId: Number(currentSchoolId) },
    })
      .then(() => {
        Toast("success", t("successAction.studentRemoved"));
      })
      .catch((error) => {
        Toast("error", error.message || t("errorAction.generalError"));
      })
      .finally(() => {
        if (
          currentPage !== 1 &&
          visibleStudentsData?.allArchivedStudents?.length === 1
        ) {
          setCurrentPage((page) => page - 1);
        }
        studentsRefetch();
        studentsMetaRefetch();
      });
  };

  const handleUnarchiveStudents = (studentIds: string[]) => {
    unarchiveStudents({
      variables: {
        studentIds: studentIds.map((item) => Number(item)),
        schoolId: Number(currentSchoolId),
      },
    })
      .then(() => {
        Toast("success", t("successAction.studentUnarchived"));
      })
      .catch((error) => {
        Toast("error", error.message || t("errorAction.generalError"));
      })
      .finally(() => {
        if (
          currentPage !== 1 &&
          visibleStudentsData?.allArchivedStudents?.length === 1
        ) {
          setCurrentPage((page) => page - 1);
        }
        studentsRefetch();
        studentsMetaRefetch();
        setSelectedStudents([]);
      });
  };

  useEffect(() => {
    studentsMetaRefetch();
    const lastPage = Math.ceil(allStudentsNumber / studentsPerPage);
    setTotalPages(lastPage);
  }, [allStudentsNumber, studentsPerPage, studentsMetaRefetch]);

  useEffect(() => {
    selectTemplateText();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplateId, emailTemplates]);

  useEffect(() => {
    studentsRefetch();
  }, [currentPage, studentsRefetch]);

  return (
    <Container onClick={closeDropdowns} ref={containerRef}>
      {visibleStudentsData && (
        <StudentMessageModal
          isOpen={isStudentActionModalOpen}
          onClose={closeAllModals}
          title={t("teacher.sendMessage")}
          selectedTemplateId={selectedTemplateId}
          onTemplateClick={onTemplateButtonPress}
          studentsAttached={areStudentsAttached}
          setStudentsAttached={toggleAttachStudents}
          searchStudentValue={searchStudent}
          setSearchStudent={handleStudentSearch}
          selectedStudents={selectedStudents}
          handleToggleStudent={handleToggleStudent}
          data={visibleStudentsData.allArchivedStudents}
          form={emailForm}
        />
      )}
      <StudentsListWrapper>
        <StudentList
          isArchivedStudentsList
          setSelectedStudents={setSelectedStudents}
          selectedStudents={selectedStudents}
          handleToggleStudent={handleToggleStudent}
          studentsData={visibleStudentsData}
          studentsLoading={studentsLoading}
          handleMessageStudent={handleMessageStudent}
          handleRemoveStudent={handleRemoveStudent}
          handleUnarchiveStudents={handleUnarchiveStudents}
          archiveYears={allArchiveYears?.allArchivedStudentsYears}
          setSelectedYear={setSelectedYear}
          onDrawerClose={() => setSelectedStudents([])}
          studentsRefetch={studentsRefetch}
          openMessageModal={() => setIsStudentActionModalOpen(true)}
        />
      </StudentsListWrapper>
      <StudentsListActions
        isArchivedStudentsList
        isAddStudentButtonEnabled={false}
        currentPage={currentPage}
        studentsPerPage={studentsPerPage}
        handlePagePress={handlePagePress}
        lastPage={totalPages}
        openPageOptionsDropdown={openPageOptionsDropdown}
        pageOptionsDropdownVisible={pageOptionsDropdownVisible}
        handleStudentPageChange={handleStudentPageChange}
        redirectToAddStudent={redirectToAddStudent}
        addStudentDropdownVisible={addStudentDropdownVisible}
        studentsNumber={allStudentsNumber}
      />
    </Container>
  );
};

export default PreviousYearsData;
